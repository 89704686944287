import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title : any;
  response: any
  constructor(private meta: Meta, private titles: Title,private http: HttpClient) {
    this.titles.setTitle('Your Page Title');
    this.meta.addTags([
      { name: 'description', content: 'Your Description' },
      { name: 'author', content: 'Your Name' },
      { name: 'keywords', content: 'Angular, SEO, SSR' },
    ]);
    this.http.get('https://jsonplaceholder.typicode.com/todos/1').subscribe((resp: any) =>{
      console.log("response",resp)
      this.response = resp
    })
  }
}
